import React from "react"
import Layout from "../components/Layout"

export default function ErrorPage() {
  return (
    <Layout>
      <h1>Errorpage</h1>
    </Layout>
  )
}
